import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Trendy",
  sku: "TCK205",
  availability: "In stock",
  title: "OCSO Solid Men's T-shirt TCK205",
  mrpPrice: "1280",
  sellPrice: "699",
  disc:
    "OCSO Men's Blue/Grey Trendy solid T-shirt, With half sleeves, Striking print logo on the front elevates the visual charm of this Blue/Grey T-shirt by ocso. Featuring a knitted design with ribbed round neckline and half sleeves, it promotes natural range of motion. Fashioned in regular fit from a blend of 95% polyester and 5% spandex",
};
export default () => {
  return <ProdLayout data={data} />;
};
